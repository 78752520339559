@media (prefers-color-scheme: light) {
  :root {
    --bg: #f4f4f4;
    --fg: #161616;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg: #000000;
    --fg: #ffffff;
  }
}

* {
  background-color: var(--bg);
  color: var(--fg);
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1.1rem;
}
